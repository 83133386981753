/* react-datasheet-grid スタイル */
.dsg-cell-header-container, .dsg-input {
    white-space: pre-wrap;
    font-size: 15px;
    padding: 0 5px;
}
.dsg-cell-header {
    color: black;
}
.dsg-container {
    height: auto !important;
}
.dsg-cell-disabled {
    background-color: rgba(198, 198, 198, 0.3);
}
.dsg-input {
    /*tooltipでwrapするためtextComponentのflex指定が効かなくなるため高さ幅を指定*/
    width: 100%;
    height: 100%;
}
.MuiDataGrid-columnHeaderTitle {
    /* ヘッダーでの改行を可能にする */
    white-space: pre-wrap !important;
}

/*MUI DataGridのStyle*/
.MuiDataGrid-cell .MuiInputBase-root {
    min-width: 1rem;
}
.MuiDataGrid-cell .MuiSelect-select {
    font-size: 0.875rem;
}
